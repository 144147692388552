import { Typography } from '@loophealth/loop-ui-web-library';
import { IPolicyProps } from './types';

import { StyledContainer, StyledIcon, StyledWrapper } from './styles';

const PolicyItem: React.FC<IPolicyProps> = ({ label, value, icon }) => {
  return (
    <StyledWrapper>
      <StyledIcon src={icon} />
      <StyledContainer>
        <Typography variant="extraSmall">{label}</Typography>
        <Typography variant="medium" weight="semiBold">
          {value}
        </Typography>
      </StyledContainer>
    </StyledWrapper>
  );
};
export default PolicyItem;
