import React, { FC } from 'react';
import { IPoliciesListProps } from './types';
import { StyledPolicies } from './styles';
import PolicyListItem from '../PolicyListItem';
import useFetchAlerts from "../TopNavigation/hooks/useFetchAlerts";

export const Policies: FC<IPoliciesListProps> = ({ policyPlans }) => {
  const { policyAlertMap } = useFetchAlerts();
  return (
    <StyledPolicies>
      {policyPlans.map((policyPlan) => (
        <PolicyListItem policy={policyPlan} key={policyPlan.id} alertData={policyAlertMap.get(policyPlan.id)}/>
      ))}
    </StyledPolicies>
  );
};
