import React from 'react';
import {
  StyledDropdownEmptyStateWrapper,
  StyledNoSearchResultsImage,
} from './styles';
import { NoSearchResultsImage } from '../../../../assets/images';
import { IDropdownEmptyState } from './types';
import { Typography } from '@loophealth/loop-ui-web-library';

const DropdownEmptyState = ({ searchText, showSearchText }: IDropdownEmptyState) => {
  return (
    <StyledDropdownEmptyStateWrapper>
      {showSearchText && (
        <Typography variant="small" color="secondary">
          Showing results for{' '}
          <Typography variant="small" weight="medium">
            "{searchText}"
          </Typography>
        </Typography>
      )}
      <StyledNoSearchResultsImage src={NoSearchResultsImage} />
    </StyledDropdownEmptyStateWrapper>
  );
};

export default DropdownEmptyState;
