import { configureStore, createAction } from '@reduxjs/toolkit';
import rootReducer from '../slices/rootReducer';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  PersistConfig,
  MigrationManifest,
  PersistedState,
} from 'redux-persist';
import createMigrate from 'redux-persist/lib/createMigrate';

// add version migration if breaking changes are made in redux
const migrations: MigrationManifest = {
  2: (_state: PersistedState) => {
    return {} as PersistedState;
  },
};

// increase the React App Version Envar if Migration is made
const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  version: Number(process.env.REACT_APP_VERSION),
  storage,
  blacklist: [],
  migrate: createMigrate(migrations, { debug: false }),
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
    });
    return process.env.REACT_APP_ENV !== 'production'
      ? defaultMiddleware
      : defaultMiddleware;
  },
});

export const cleanup = createAction('common/cleanup');
export const demoCleanup = createAction('common/demoCleanup');
export const persistor = persistStore(store);
export default store;
export type RootState = ReturnType<typeof rootReducer>;