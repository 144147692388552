import { IBulkOperation } from '../Bulk/types';

export const TEXT_CONSTANTS: Record<IBulkOperation, Record<string, string>> = {
  ADD: {
    TITLE: 'Upload Addition Data',
    SUBTITLE:
      'Download and start adding members. If you’re ever stuck, our guided tutorial is just a click away.',
  },
  DELETE: {
    TITLE: 'Upload Deletion Data',
    SUBTITLE:
      'Download and start deleting members. If you’re ever stuck, our guided tutorial is just a click away.',
  },
  EDIT: {},
};
