import React, { FC } from 'react';
import {
  StyledAnalyticsHeader,
  StyledDropdownItem,
  StyledFilter,
  StyledTitle,
  StyledTitleText,
} from './styles';
import { Form, Typography } from '@loophealth/loop-ui-web-library';
import { IAnalyticsHeaderProps } from './types';

const AnalyticsHeader: FC<IAnalyticsHeaderProps> = ({
  title,
  subtitle,
  policiesOptions,
  selectedPolicyFilter,
  setSelectedPolicyFilter,
}) => {
  const renderDropdown = (
    data: Record<string, unknown>,
    isSelected: boolean,
  ) => (
    <StyledDropdownItem $isVisible={!data.isHidden}>
      <Typography
        variant="small"
        weight={isSelected ? 'semiBold' : 'medium'}
        color={isSelected ? 'emerald' : 'primary'}
      >
        {data.name as string}
      </Typography>
      {!data.isHidden && (
        <Typography variant="extraSmall" weight="medium">
          {data.nickName as string}&nbsp;
          <Typography variant="extraSmall" color="secondary">
            | {data.insurer as string} | Policy No: {data.policyNo as string}
          </Typography>
        </Typography>
      )}
    </StyledDropdownItem>
  );

  return (
    <StyledAnalyticsHeader data-testid="analytics-header">
      <StyledTitle data-testid="analytics-title">
        <StyledTitleText>
          <Typography variant="title3" weight="medium">
            {title}
          </Typography>
          {subtitle && <Typography variant="extraSmall">{subtitle}</Typography>}
        </StyledTitleText>
        <Typography variant="small" color="secondary" weight="medium">
          Showing for
        </Typography>
      </StyledTitle>

      <StyledFilter data-testid="analytics-dropdown">
        <Form.Dropdown
          placeholder="All policies"
          onClick={setSelectedPolicyFilter}
          selectedItem={selectedPolicyFilter}
          listItems={policiesOptions}
          renderItem={renderDropdown}
        />
      </StyledFilter>
    </StyledAnalyticsHeader>
  );
};

export default AnalyticsHeader;
