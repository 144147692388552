import styled from 'styled-components';

export const StyledStatusWidget = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.mintFrost};
  padding: 35px 56px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  text-transform: capitalize;
`;

export const StyledBullet = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledIcon = styled.img`
  cursor: pointer;
`;
