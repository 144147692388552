import { IClaimListStatusState, IClaimStatusTags } from './types';
import { IClaimsListHeaderStatus } from '../../containers/ClaimListHeader/types';
import {
  EmptyStateFiveGif,
  EmptyStateOneGif,
  EmptyStateSevenGif,
  EmptyStateThreeGif,
  EmptyStateTwoGif,
} from '../../../assets/images';
import { IClaimStatus } from '../../../utils/constants';
import { IBadgeType } from '@loophealth/loop-ui-web-library/dist/types/atoms/LoopBadge/types';

export const CLAIM_STATUS_TO_HEADER_TAB: Record<
  IClaimStatus,
  IClaimsListHeaderStatus
> = {
  'Claim Intimated': 'ongoing',
  'Claim Under Query': 'under-query',
  'Claim Under Review': 'under-query',
  'Claim Paid': 'ongoing',
  'Claim Closed Disabled': 'closed',
  'Claim Under Review Disabled': 'closed',
  'Claim Closed': 'closed',
  'Claim Processed': 'settled',
  'Claim Rejected': 'rejected',
};

export const ALL_CLAIMS_CONSTANT = 'all';

export const ClaimStatusList = {
  Ongoing: 'Ongoing',
  Under_Query: 'Under Query',
  Closed: 'Closed',
  Rejected: 'Rejected',
  Settled: 'Settled',
};

export const CLAIMS_LIST_STATUS_STATE: IClaimListStatusState = {
  all: {
    emptyState: {
      title: 'No open claims!',
      desc: 'Kudos on keeping your employees healthy!',
      note: 'Note: Claims can take 2-3 days to reflect here',
      image: EmptyStateOneGif,
    },
  },
  'Under Query': {
    emptyState: {
      title: 'No claims under query!',
      desc: 'No claims are blocked due to pending documents',
      image: EmptyStateTwoGif,
    },
  },
  Ongoing: {
    emptyState: {
      title: 'No ongoing claims!',
      desc: 'There are no claims open right now',
      note: 'Note: Claims can take 2-3 days to reflect here',
      image: EmptyStateTwoGif,
    },
  },
  Rejected: {
    emptyState: {
      title: 'No rejected claims!',
      desc: 'We do our best to settle all your claims :)',
      image: EmptyStateFiveGif,
    },
  },
  Settled: {
    emptyState: {
      title: 'No settled claims!',
      desc: 'Your peace of mind is our priority. We do our best to settle claims on time',
      image: EmptyStateSevenGif,
    },
  },
  Closed: {
    emptyState: {
      title: 'No closed claims!',
      desc: 'Your employees have impeccable documentation skills',
      image: EmptyStateThreeGif,
    },
  },
};
export const claimsCountInitialState = {
  Ongoing: 0,
  'Under Query': 0,
  Closed: 0,
  Rejected: 0,
  Settled: 0,
};

export const claimStatusBadgeVariant: Record<IClaimStatusTags, IBadgeType> = {
  Ongoing: 'warning',
  'Under Query': 'error',
  Closed: 'error',
  Rejected: 'error',
  Settled: 'success2',
};
