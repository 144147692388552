import LoopEndpoints from './LoopEndpoints';
import { get } from './providers';

const EmployeeApi = {
  getEmpDependents: (employeeId: string) =>
    get(LoopEndpoints.fetchEmpDependents(employeeId)),

  getEmployeeDetails: (employeeId: string) =>
    get(LoopEndpoints.fetchEmpFamilyDetails(employeeId)),

  fetchECard: (userId: string, policyId: string) =>
    get(LoopEndpoints.fetchECardDetails(userId, policyId)),
};

export default EmployeeApi;
