import { capitalizeFirstLetterOfEachWord } from '../../../utils/common/Utilities';
import {
  INavigationSearchResultsType,
  ISearchResultResponseUserType,
} from './types';

export const getFormattedSearchResults = (
  users: ISearchResultResponseUserType[],
): INavigationSearchResultsType[] =>
  users.map((searchResult) => ({
    title: capitalizeFirstLetterOfEachWord(
      `${searchResult.firstName} ${searchResult.lastName ?? ''}`,
    ),
    subtitle: `Employee ID: ${searchResult.employeeId}`,
    link: '',
  }));
