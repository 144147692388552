import { useDispatch, useSelector } from 'react-redux';
import {
  selectClaimList,
  selectSelectedCompany,
} from '../../../../redux/slices/hrdRevampRedux';
import { useEffect } from 'react';
import { fetchClaimList } from '../../../../redux/slices/hrdRevampRedux/thunk';

const useFetchClaimList = () => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectSelectedCompany);
  const { data, error, status } = useSelector(selectClaimList);
  const isLoading = status === 'loading'

  useEffect(() => {
    if (selectedCompany?.id && !isLoading) {
      dispatch(fetchClaimList(selectedCompany?.id));
    }
  }, [selectedCompany?.id, dispatch]);
  return { claims: data, isLoading , error };
};
export default useFetchClaimList;
