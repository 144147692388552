import {
  ClaimAnalyticsMenuIcon,
  ClaimAnalyticsSelectedMenuIcon,
  ClaimsMenuIcon,
  ClaimsSelectedMenuIcon,
  DashboardMenuIcon,
  DashboardSelectedMenuIcon,
  EmployeesMenuIcon,
  EmployeesSelectedMenuIcon,
  EndorsementsMenuIcon,
  EndorsementsSelectedMenuIcon,
  PoliciesMenuIcon,
  PoliciesSelectedMenuIcon,
  WalletIcon,
} from '../../../assets/images';

export const getHRDMenuItems = (isEndoEnabled: boolean) => {
  return [
    {
      id: 'policies-page',
      name: 'Policies',
      icon: PoliciesMenuIcon,
      selectedIcon: PoliciesSelectedMenuIcon,
      routes: ['/policies', '/policy-details'],
    },
    {
      id: 'employees-page',
      name: 'Employees',
      icon: EmployeesMenuIcon,
      selectedIcon: EmployeesSelectedMenuIcon,
      routes: ['/employees', '/select-policies', '/employee-details'],
    },
    {
      id: 'claim-details-page',
      name: 'Claim Details',
      icon: ClaimsMenuIcon,
      selectedIcon: ClaimsSelectedMenuIcon,
      routes: ['/claims', '/claim-details'],
    },
    {
      id: 'claim-analytics-page',
      name: 'Claim Analytics',
      icon: ClaimAnalyticsMenuIcon,
      selectedIcon: ClaimAnalyticsSelectedMenuIcon,
      routes: ['/claim-analytics'],
    },

    {
      id: 'endorsement-page',
      name: 'Endorsements',
      icon: EndorsementsMenuIcon,
      selectedIcon: EndorsementsSelectedMenuIcon,
      routes: [
        '/endorsements',
        '/bulk-add',
        '/bulk-edit',
        '/bulk-delete',
        '/endorsement-details',
        '/bulk-register',
      ],
      isDisabled: !isEndoEnabled,
    },
    {
      id: 'cd-account-page',
      name: 'CD Accounts',
      icon: WalletIcon,
      selectedIcon: WalletIcon,
      routes: ['/cd-accounts'],
    },
  ];
};
