import { SideNavigation } from '@loophealth/loop-ui-web-library';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { FirebaseAuth } from '../../../adaptars/providers';
import { useAuth } from '../../../contexts/AuthContext';
import { selectCompanyData } from '../../../redux/slices/companySlice';
import {
  fetchCompanyById,
  fetchCompanyConfig,
} from '../../../redux/slices/companySlice/thunks';
import {
  selectSelectedCompany,
  setSelectedCompany,
} from '../../../redux/slices/hrdRevampRedux';
import {
  fetchAllActivePolicies,
  fetchCDAccounts,
  fetchClaimList,
  fetchEndorsments,
} from '../../../redux/slices/hrdRevampRedux/thunk';
import { selectHrUser } from '../../../redux/slices/usersSlice';
import { cleanup } from '../../../redux/store';
import { trackTaskEvent } from '../../../utils/segment/utils';
import {
  isEndoBulkEditEnabled,
  isHRPulseEnabled,
} from '../../../utils/featureFlag';
import { PrivateRoute } from '../../../utils/hoc/PrivateRoute';
import { useCompanyList } from '../../../utils/hooks/business-hooks/useCompanyList';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import Backdrop from '../../atoms/Backdrop/Backdrop';
import Loader from '../../atoms/Spinner';
import FeedbackSidebar from '../../containers/HRFeedback/FeedbackSidebar';
import FeedbackFooter from '../../containers/HRFeedback/FeedbackFooter';
import TopNavigation from '../../containers/TopNavigation';
import Analytics from '../Analytics';
import CDAccounts from '../CDAccounts';
import ClaimList from '../ClaimsList';
import EmployeeDetails from '../EmployeeDetails';
import Employees from '../Employees';
import BulkAddMembers from '../Endorsements/BulkAddMembers';
import BulkDeleteMembers from '../Endorsements/BulkDeleteMembers';
import BulkEditMembers from '../Endorsements/BulkEditMembers';
import EndoDetails from '../Endorsements/EndoDetails';
import Endorsements from '../Endorsements/ListingPage';
import '../HrDashboard.scss';
import PoliciesDashboard from '../Policies';
import PolicyDetails from '../PolicyDetails';
import { getHRDMenuItems } from './constants';
import {
  StyledAppContainer,
  StyledChildComponent,
  StyledComponentContainer,
} from './styles';
import { IComponentRoutes, IHRDApp, INewNavBarHRDApp } from './types';
import { showErrorToast } from '../../../utils/common/Utilities';

const ComponentRoutes: React.FC<IComponentRoutes> = ({ isEndoEnabled }) => {
  return (
    <StyledChildComponent>
      <Switch>
        <PrivateRoute path="/policies" component={PoliciesDashboard} />
        <PrivateRoute path="/policy-details" component={PolicyDetails} />

        <PrivateRoute path="/employees" component={Employees} />
        <PrivateRoute path="/employee-details" component={EmployeeDetails} />

        <PrivateRoute path="/claims" component={ClaimList} />
        <PrivateRoute path="/claim-analytics" component={Analytics} />

        <PrivateRoute path="/cd-accounts" component={CDAccounts} />

        {isEndoEnabled && [
          <PrivateRoute
            path="/bulk-add"
            component={BulkAddMembers}
            key="bulk-add"
          />,
          <PrivateRoute
            path="/bulk-delete"
            component={BulkDeleteMembers}
            key="bulk-delete"
          />,
          <PrivateRoute
            path="/endorsements"
            component={Endorsements}
            key="endorsements"
          />,
          <PrivateRoute
            path="/endorsement-details"
            component={EndoDetails}
            key="endorsement-details"
          />,
        ]}
        {isEndoEnabled && isEndoBulkEditEnabled && (
          <PrivateRoute path="/bulk-edit" component={BulkEditMembers} />
        )}
        <Redirect to="/policies" />
      </Switch>
    </StyledChildComponent>
  );
};

const NewNavBarHRDApp: React.FC<INewNavBarHRDApp> = ({
  hrData,
  companyList,
  onClickLogout,
  onMenuClicked,
  selectedCompany,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const hrUser = useSelector(selectHrUser);
  const loopUser = hrUser?.data?.email?.includes('@loophealth.com');
  const isHRPulseFeatureEnabled =
    loopUser && process.env.REACT_APP_ENV === 'production'
      ? false
      : isHRPulseEnabled;

  return (
    <>
      <TopNavigation
        hrData={hrData?.data}
        companies={companyList}
        onClickLogout={onClickLogout}
      />
      {isHRPulseFeatureEnabled && (
        <FeedbackSidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
      )}
      <StyledComponentContainer>
        <SideNavigation
          isExpended={true}
          isFixedSideBar={true}
          menuItems={getHRDMenuItems(selectedCompany.isEndoEnabled)}
          onMenuClicked={onMenuClicked}
          closeNavigationDrawer={() => ''}
          footer={
            isHRPulseFeatureEnabled && (
              <FeedbackFooter setIsSidebarOpen={setIsSidebarOpen} />
            )
          }
        />
        <ComponentRoutes isEndoEnabled={selectedCompany.isEndoEnabled} />
      </StyledComponentContainer>
    </>
  );
};

const HRDApp: React.FC<IHRDApp> = ({
  hrData,
  companyList,
  onClickLogout,
  onMenuClicked,
  selectedCompany,
}) => {
  return companyList.length ? (
    <NewNavBarHRDApp
      hrData={hrData}
      companyList={companyList}
      onClickLogout={onClickLogout}
      onMenuClicked={onMenuClicked}
      selectedCompany={selectedCompany}
    />
  ) : (
    <Backdrop />
  );
};

const Dashboard: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loadingCompanyList, companyList } = useCompanyList();
  const selectedCompany = useSelector(selectSelectedCompany);
  const { logout, setLoggedInUser } = useAuth();
  const companyDetails = useSelector(selectCompanyData);

  const hrData = useSelector(selectHrUser);
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const callTrackClick = (action: string, category: string) =>
    trackClick(trackClickEvent(action, location?.pathname, category));
  const callTrackTask = (
    action: string,
    category: string,
    data?: Record<string, unknown>,
  ) =>
    trackTask(
      trackTaskEvent(action, location?.pathname, category, data),
    );

  useEffect(() => {
    FirebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        performLogout();
      }
    });
  }, []);

  useEffect(() => {
    if (hrData.data?.userId) {
      window.plotline(
        'init',
        process.env.REACT_APP_PLOTLINE_KEY!,
        hrData.data.email,
      );
    }
  }, [hrData.data?.userId]);

  useEffect(() => {
    if (!loadingCompanyList && !companyList?.length) {
      showErrorToast('No company is mapped with your account');
      performLogout();
    }
    if (!selectedCompany && companyList.length) {
      dispatch(setSelectedCompany({ selectedCompany: companyList[0] }));
    }
  }, [loadingCompanyList, selectedCompany, companyList, dispatch]);

  useEffect(() => {
    if (selectedCompany) {
      const isCompanyChanged =
        !companyDetails.data || companyDetails.data.id !== selectedCompany.id;
      if (isCompanyChanged && companyDetails.status !== 'loading') {
        dispatch(fetchCompanyById(selectedCompany.id));
        dispatch(fetchCompanyConfig(selectedCompany.id));
      }
      // Loading Claims & CD Accounts for Alerts -
      location.pathname !== '/claims' &&
        dispatch(fetchClaimList(selectedCompany.id));
      location.pathname !== '/cd-accounts' &&
        dispatch(fetchCDAccounts(selectedCompany.id));
      location.pathname !== '/policies' &&
        dispatch(fetchAllActivePolicies(selectedCompany.id));
      location.pathname !== '/endorsements' &&
        dispatch(fetchEndorsments(selectedCompany.id));
      callTrackTask('ENDORSEMENTS_VISIBILITY', 'HRD ENDO SELF SERVE', {
        isEndoVisible: selectedCompany.isEndoEnabled,
      });
    }
  }, [selectedCompany?.id]);

  const onClickLogout = async () => {
    try {
      if (window.confirm('Are you sure')) {
        callTrackClick('LOGOUT', 'HRD Login&Logout Events');
        try {
          await performLogout();
        } catch (error) {
          callTrackTask('LOGOUT_FAIL', 'HRD Login&Logout Events');
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const performLogout = async (navigationRoute = '/login') => {
    window.plotline('logout', process.env.REACT_APP_PLOTLINE_KEY!);
    setLoggedInUser(null);
    dispatch(cleanup());
    await logout();
    callTrackTask('LOGOUT_SUCCESS', 'HRD Login&Logout Events');
    history.replace(navigationRoute);
  };

  const onMenuClicked = (routeName: string) => {
    if (location.pathname !== routeName) {
      trackClick(
        trackClickEvent(extractPathName(routeName), location?.pathname),
      );
      history.push(routeName);
    }
  };

  return (
    <StyledAppContainer>
      {!loadingCompanyList && companyList && selectedCompany ? (
        <HRDApp
          hrData={hrData}
          companyList={companyList}
          onClickLogout={onClickLogout}
          onMenuClicked={onMenuClicked}
          selectedCompany={selectedCompany}
        />
      ) : (
        <Loader />
      )}
    </StyledAppContainer>
  );
};
export default Dashboard;
