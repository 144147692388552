import { useSelector } from 'react-redux';
import { selectPoliciesList } from '../../../../redux/slices/hrdRevampRedux';
import { useMemo } from 'react';
import { IUploadedPolicywiseData } from '../../../pages/Endorsements/BulkAddMembers/types';
import { IBulkDeleteData } from '../../../pages/Endorsements/BulkDeleteMembers/types';
import { IBulkEditEntity } from '../../../pages/Endorsements/BulkEditMembers/types';
import { IBulkOperation } from '../types';
import { transformEditDataForEstimateRequest } from '../SubmitEndo/utils';

const useEndoSummaryData = (
  mode: IBulkOperation,
  finalData: IUploadedPolicywiseData | IBulkDeleteData[] | IBulkEditEntity[],
) => {
  const policyList = useSelector(selectPoliciesList)?.data;
  return useMemo(() => {
    if (mode === 'DELETE') return {};

    const policyTypeBasedListing: Record<string, number> = {};
    const submittedData =
      mode === 'ADD'
        ? (finalData as IUploadedPolicywiseData)
        : transformEditDataForEstimateRequest(finalData as IBulkEditEntity[]);
    Object.keys(submittedData).forEach((policyId) => {
      const policyData = policyList.find(
        (policyData) => policyData.id === policyId,
      );
      if (policyData?.policyType) {
        if (policyTypeBasedListing[policyData?.policyType]) {
          policyTypeBasedListing[policyData?.policyType] +=
            submittedData[policyId].length;
        } else
          policyTypeBasedListing[policyData?.policyType] =
            submittedData[policyId].length;
      }
    });

    return policyTypeBasedListing;
  }, [policyList, finalData, mode]);
};

export default useEndoSummaryData;
