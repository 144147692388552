import { Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { formatCurrencyAmount } from '../../../../utils/common/Utilities';
import { StyledVerticalSpacer } from '../../ClaimDetails/styles';
import {
  StyledInsurerLogo,
  StyledPolicyContainer,
  StyledPolicyCostData,
  StyledPolicyCostSection,
  StyledPolicyHeader,
  StyledPolicyItemAlertsContainer,
  StyledPolicyList,
  StyledPolicyName,
  StyledPolicyType,
} from './styles';
import { IEndoCDAccountWiseCost } from './types';
import { SUBMIT_SCREEN_COST_REFUND } from '../constants';

const EndoCDAccountWiseCost: React.FC<IEndoCDAccountWiseCost> = ({
  endoCostData,
  mode,
}) => {
  return (
    <StyledPolicyContainer>
      <StyledPolicyHeader>
        <StyledPolicyName>
          <StyledInsurerLogo src={endoCostData.insurerLogo} />
          <Typography variant="small" weight="medium">
            {endoCostData.insurer}
          </Typography>
        </StyledPolicyName>
        <StyledPolicyType>
          <Typography variant="small" color="secondary">
            CD Balance:{' '}
            <Typography
              variant="small"
              weight="medium"
              color={endoCostData.isCDLow ? 'error' : 'primary'}
            >
              {formatCurrencyAmount(endoCostData.balance)}
            </Typography>
          </Typography>
        </StyledPolicyType>
      </StyledPolicyHeader>
      <StyledPolicyList>
        {endoCostData.isCDLow && (
          <StyledPolicyItemAlertsContainer>
            <Typography variant="small" weight="medium" color="error">
              CD Balance low! Please add more funds to process these
              endorsements
            </Typography>
          </StyledPolicyItemAlertsContainer>
        )}
        {endoCostData.policyData.map((policyData, index) => (
          <StyledPolicyCostSection key={index}>
            <StyledPolicyCostData>
              <Typography variant="small" color="secondary">
                Policy Type
              </Typography>
              <Typography variant="small" weight="medium">
                {policyData.policyType}
              </Typography>
            </StyledPolicyCostData>
            <StyledVerticalSpacer />
            {mode === 'ADD' && (
              <>
                <StyledPolicyCostData>
                  <Typography variant="small" color="secondary">
                    Lives Added
                  </Typography>
                  <Typography variant="small" weight="medium">
                    {policyData.lives}
                  </Typography>
                </StyledPolicyCostData>
                <StyledVerticalSpacer />
              </>
            )}
            {mode === 'DELETE' && (
              <>
                <StyledPolicyCostData>
                  <Typography variant="small" color="secondary">
                    Lives Deleted
                  </Typography>
                  <Typography variant="small" weight="medium">
                    {policyData.lives}
                  </Typography>
                </StyledPolicyCostData>
                <StyledVerticalSpacer />
              </>
            )}
            <StyledPolicyCostData>
              <Typography variant="small" color="secondary">
                Endorsement{' '}
                {policyData.costOfEndorsement < 0
                  ? 'Refund'
                  : SUBMIT_SCREEN_COST_REFUND[mode].costText}
              </Typography>
              <Typography variant="small" weight="medium">
                {formatCurrencyAmount(Math.abs(policyData.costOfEndorsement))}
              </Typography>
            </StyledPolicyCostData>
          </StyledPolicyCostSection>
        ))}
      </StyledPolicyList>
    </StyledPolicyContainer>
  );
};

export default EndoCDAccountWiseCost;
