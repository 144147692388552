import React from 'react';
import Loader from '../Spinner';
import { StyledBackdrop } from './styles';

const Backdrop = () => (
  <StyledBackdrop>
    <Loader />
  </StyledBackdrop>
);

export default Backdrop;
