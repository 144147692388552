import { remoteConfig } from '../../firebase';

const Feature = {
  ENDORSEMENTS: 'REACT_APP_FEATURE_ENDORSEMENTS',
  BULK_EDIT: 'REACT_APP_FEATURE_BULK_EDIT',
  ENDO_NOTIFICATIONS: 'REACT_APP_ENDO_NOTIFICATIONS',
  POLICY_PAL: 'REACT_APP_POLICY_PAL',
  HR_FEEDBACK_PULSE: 'REACT_APP_HR_FEEDBACK_PULSE',
  NEW_CD_BALANCE_DETAILS: 'REACT_APP_NEW_CD_BALANCE_DETAILS',
  EDIT_FIELDS_DOC_MAPPING: 'REACT_APP_EDIT_FIELDS_DOC_MAPPING',
  DEMO_MODE: 'REACT_APP_DEMO_MODE',
};

const isFeatureEnabled = (feature: string) => {
  const env = process.env;
  if (Object.keys(env).includes(feature.toString())) {
    return JSON.parse(env[feature.toString()] || 'false');
  }
  return false;
};
export const isProduction = process.env.REACT_APP_ENV === 'production';
export const isEndoVisible = isFeatureEnabled(Feature.ENDORSEMENTS);
export const isEndoNotificationEnabled = isFeatureEnabled(Feature.ENDO_NOTIFICATIONS);
export const isEndoBulkEditEnabled = isFeatureEnabled(Feature.BULK_EDIT);
export const isPolicyPalEnabled = isFeatureEnabled(Feature.POLICY_PAL);
export const isHRPulseEnabled = isFeatureEnabled(Feature.HR_FEEDBACK_PULSE);
export const isNewCDBalanceDetailsEnabled = isFeatureEnabled(Feature.NEW_CD_BALANCE_DETAILS);
export const isEditFieldsDocMappingEnabled = isFeatureEnabled(Feature.EDIT_FIELDS_DOC_MAPPING);
export const isDemoModeEnabled = isFeatureEnabled(Feature.DEMO_MODE);

export const fetchRemoteConfig = async (key: string) => {
  try {
    await remoteConfig.fetchAndActivate();
    const policyPalHrAccessAsString = remoteConfig.getValue(key).asString();
    const policyPalHrAccess = JSON.parse(policyPalHrAccessAsString);
    return policyPalHrAccess;
  } catch (err) {
    throw err;
  }
};
