import React from 'react';
import TabWithDetail from '../../atoms/TabWithDetail';
import { CLAIMS_LIST_HEADER_TABS } from './constants';
import {
  StyledClaimListHeader,
  StyledClaimListHeaderTabsContainer,
} from './styles';
import { IClaimListHeader } from './types';
import ClaimsHeaderDropdown from '../ClaimsHeaderDropdown';
import { useSelector } from 'react-redux';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import { getClaimsHeaderSubtitle } from '../../pages/ClaimsList/utils';

const ClaimListHeader = ({
  selectedTab,
  selectedFilter,
  setSelectedFilter,
  setSelectedTab,
  claimCounts,
  policiesOptions,
}: IClaimListHeader) => {
  const { data } = useSelector(selectPoliciesList);
  const subtitle = getClaimsHeaderSubtitle(data);

  return (
    <StyledClaimListHeader>
      <ClaimsHeaderDropdown
        title="Claims Details"
        subtitle={subtitle}
        policiesOptions={policiesOptions}
        selectedPolicyFilter={selectedFilter}
        setSelectedPolicyFilter={setSelectedFilter}
      />
      <StyledClaimListHeaderTabsContainer>
        {CLAIMS_LIST_HEADER_TABS.map((item) => (
          <TabWithDetail
            key={item.label}
            color={item.color}
            label={item.label}
            count={claimCounts[item.status]}
            isSelected={selectedTab === item.status}
            onClick={() => setSelectedTab(item.status)}
            tooltip={item.tooltip}
          />
        ))}
      </StyledClaimListHeaderTabsContainer>
    </StyledClaimListHeader>
  );
};

export default ClaimListHeader;
