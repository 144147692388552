import styled from 'styled-components';

export const StyledMemberSearch = styled.div`
  padding: 32px 48px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
`;

export const StyledLoopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledLoopIcon = styled.img`
  width: 56px;
  height: 27px;
`;

export const StyledMemberSearchContainer = styled.div`
  border-radius: 12px;
  padding: 48px 96px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  width: 924px;
`;

export const StyledMemberSearchHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  text-align: center;
`;

export const StyledMemberDropdownItem = styled.div`
  display: flex;
  gap: 8px;
  padding: 0 4px;
  text-transform: capitalize;
`;

export const StyledMemberDropdownItemContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const StyledDropdownLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 690px;
  height: 26px;
`;

export const StyledNoSearchResultsImage = styled.img`
  pointer-events: none;
`;

export const StyledMemberSearchBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  align-items: center;
`;

export const StyledSearchedDependentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const StyledSearchedDependentsTable = styled.div`
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.white};
  padding: 8px;
  width: 924px;
`;

export const StyledNameColumnWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledDropdownEmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;