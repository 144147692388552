import React from 'react';
import {
  DateFormatUtils,
  LoopBadge,
  Tooltip,
  Typography,
} from '@loophealth/loop-ui-web-library';
import {
  capitalizeFirstLetterOfEachWord,
  textPlaceholder,
} from '../../../utils/common/Utilities';
import { StyledClaimAgeHeader, StyledClaimIdUnderline } from './styles';
import { InfoDarkIcon } from '../../../assets/images';
import {
  IClaimData,
  IClaimForTableUI,
  IClaimStatusTabs,
  IClaimStatusTags,
} from './types';
import {
  ALL_CLAIMS_CONSTANT,
  claimsCountInitialState,
  claimStatusBadgeVariant,
  ClaimStatusList,
} from './constants';
import moment from 'moment';
import { IPolicyListItemType } from '../../../redux/slices/hrdRevampRedux/types';
import { IDropdownItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types';
import { IPolicyTypes, PolicyTypeConstants } from '../../../utils/constants';
import { IBadgeType } from '@loophealth/loop-ui-web-library/dist/types/atoms/LoopBadge/types';

export const getFilteredClaims = (
  claims: IClaimData[],
  selectedTab: IClaimStatusTabs,
  policyId: string,
  policyNo: string | null | undefined,
): IClaimForTableUI[] => {
  let filteredClaim: IClaimData[] = claims || [];
  if (policyId && policyId !== ALL_CLAIMS_CONSTANT) {
    filteredClaim = claims.filter(
      (claimData) =>
        claimData.policyNo === policyNo &&
        (selectedTab === ALL_CLAIMS_CONSTANT ||
          claimData.status?.tag === selectedTab),
    );
  } else if (selectedTab !== ALL_CLAIMS_CONSTANT) {
    filteredClaim = claims.filter(
      (claimData) => claimData.status?.tag === selectedTab,
    );
  }
  return transformClaimsListForUI(filteredClaim);
};

export const getClaimsCount = (
  claims: IClaimData[],
  policyId: string | null,
  policyNo: string | undefined,
): Record<IClaimStatusTabs, number> => {
  const isPolicyFilterNotApplied = policyId === ALL_CLAIMS_CONSTANT;
  const policyFiltered = isPolicyFilterNotApplied
    ? claims
    : claims.filter((claimData) => claimData.policyNo === policyNo);
  const statusClaimsCounts: Record<IClaimStatusTabs, number> = {
    [ALL_CLAIMS_CONSTANT]: policyFiltered.length,
    ...claimsCountInitialState,
  };
  policyFiltered.forEach((claimData) => {
    const status = claimData.status?.tag as IClaimStatusTags;
    if (
      status in statusClaimsCounts &&
      (isPolicyFilterNotApplied || claimData.policyNo === policyNo)
    ) {
      statusClaimsCounts[status]++;
    }
  });
  return statusClaimsCounts;
};

export const CLAIM_LIST_HEADERS = [
  { name: 'Employee Name', flex: 5 },
  { name: 'Emp. ID', flex: 2 },
  { name: 'Intimation date', flex: 3 },
  { name: 'Patient Name', flex: 5 },
  { name: 'Relationship', flex: 2 },
  // {name: 'Claim ID', flex: 2},
  { name: 'Claim Age', flex: 2 },
  { name: 'Status', flex: 2 },
];
export const CLAIM_TABLE_PROCESSOR = {
  'Employee Name': {
    cell: function <T>(value: T) {
      return (
        <StyledClaimIdUnderline>
          <Typography color="emerald" variant="small">
            {value as string}
          </Typography>
        </StyledClaimIdUnderline>
      );
    },
  },
  'Claim Age': {
    header: function <T>(value: T) {
      return (
        <StyledClaimAgeHeader>
          {value as string}
          <Tooltip
            beak="top-center"
            type="informative"
            text="Claim age is calculated from the day the last documents were submitted by the employee"
          >
            <img src={InfoDarkIcon} alt="info icon" />
          </Tooltip>
        </StyledClaimAgeHeader>
      );
    },
    cell: function <T>(value: T) {
      return <>{(value as number) > -1 ? value + ' Days' : '--'}</>;
    },
  },
  Status: {
    cell: function <T>(value: T, _: number, data: Record<string, unknown>) {
      const badgeTyp = value
        ? (claimStatusBadgeVariant[value as IClaimStatusTags] as IBadgeType)
        : 'grey';
      return (
        <LoopBadge
          key={data.id as string}
          badgeType={badgeTyp}
          variant={'overflow'}
          isAnimated={value === ClaimStatusList.Settled}
          text={capitalizeFirstLetterOfEachWord(value as string)}
          fullWidth
        />
      );
    },
  },
};

export const transformClaimsListForUI = (
  claims: IClaimData[],
): IClaimForTableUI[] => {
  return claims
    .map((data) => {
      return {
        id: data.id,
        'Employee Name': capitalizeFirstLetterOfEachWord(data.employeeName),
        'Emp. ID': data.employeeCode,
        'Intimation date': new DateFormatUtils().formatDate(
          data.claimIntimatedAt,
        ),
        'Patient Name': capitalizeFirstLetterOfEachWord(data.benificiaryName),
        Relationship: capitalizeFirstLetterOfEachWord(data.relationship),
        'Claim ID': data.tpaClaimId,
        'Claim Age':
          data.lastStatusDate && data.claimReceivedAt
            ? moment(data.lastStatusDate || new Date()).diff(
                moment(data.claimReceivedAt),
                'days',
              )
            : -1,
        status: data.status?.tag ?? '--',
        Status: data.status?.tag ?? '--',
        'Insurance Type': data.type,
        'Policy Id': data.policyNo,
        userId: data.userId,
      };
    })
    .sort((claim1, claim2) => claim1['Claim Age'] - claim2['Claim Age']);
};

export const getTableHeader = (tab: string, length: number) =>
  `${
    capitalizeFirstLetterOfEachWord(tab.replace('-', ' ')) + ' Claims'
  } (${length})`;

export const getPolicyListForClaimsDropDown = (
  policies: IPolicyListItemType[],
  isAllEnabled: boolean = true,
): IDropdownItem[] => {
  const allPoliciesOption = {
    name: 'All Group Medical Cover Policies',
    value: ALL_CLAIMS_CONSTANT,
  };
  const filteredPolicies = policies
    .filter((policy) => policy.policyType === 'GMC')
    .map((item: IPolicyListItemType) => {
      return {
        name: `${
          IPolicyTypes[item.policyType as keyof typeof IPolicyTypes]
        } | Policy No: ${textPlaceholder(item.policyNumber)} `,
        value: item.id,
        data: {
          policyType: item.policyType,
          nickName: item.nickName,
          name: IPolicyTypes[item.policyType as keyof typeof IPolicyTypes],
          policyNo: textPlaceholder(item.policyNumber),
          insurer: item.insurerName,
        },
      };
    });
  return isAllEnabled && filteredPolicies?.length > 1
    ? [allPoliciesOption, ...filteredPolicies]
    : filteredPolicies;
};

export const getClaimsHeaderSubtitle = (policies: IPolicyListItemType[]) => {
  let isGpaAvailable = false;
  let isGtlAvailable = false;
  policies?.forEach((policy) => {
    isGpaAvailable =
      isGpaAvailable || policy.policyType === PolicyTypeConstants.GPA;
    isGtlAvailable =
      isGtlAvailable || policy.policyType === PolicyTypeConstants.GTL;
  });
  const subtitle = `*Does not include claims for ${
    isGpaAvailable ? 'GPA' : isGtlAvailable ? 'GTL' : ''
  } ${isGpaAvailable && isGtlAvailable ? 'and GTL' : ''} policies`;
  return isGpaAvailable || isGtlAvailable ? subtitle : undefined;
};
