import {
  CalenderRoundedIcon,
  DataCorrectPlaceholder,
  DataErrorsPlaceholder,
  DataProcessPlaceholder,
  ManIcon,
  PhoneIcon,
  ProfileIcon,
  ProfileTwoUserIcon,
  SmsIcon,
} from '../../../assets/images';
import { IFullPageLoader } from '../../atoms/Loader/types';
import { IEditableFields } from '../../pages/Endorsements/BulkEditMembers/types';
import { IBulkOperation } from './types';

export const LOADING_CONSTANTS: Record<string, IFullPageLoader> = {
  VALIDATE_MEMBERS: {
    image: DataProcessPlaceholder,
    type: 'loading',
    title: 'Verifying your data...',
    subtitle: "Hold on, we're checking your data for any hiccups!",
  },
  VALIDATE_MID_TERMS: {
    image: DataProcessPlaceholder,
    type: 'loading',
    title: 'Verifying your data...',
    subtitle: "Almost there! We're looking into the data you just provided",
  },
  VALIDATION_HAS_ERRORS: {
    image: DataErrorsPlaceholder,
    type: 'error',
    title: 'Your data is verified...',
    subtitle: 'Oops! We found some errors. Let’s get them Fixed.',
  },
  VALIDATION_HAS_NO_ERRORS: {
    image: DataCorrectPlaceholder,
    type: 'success',
    title: 'Your data is verified...',
    subtitle: 'Voila! Your data is spot-on. No Errors Found.',
  },
  CHECKING_ENDO_COST: {
    image: DataProcessPlaceholder,
    type: 'loading',
    title: 'Checking Endorsement Cost...',
    subtitle: "We're calculating the total cost of your edits. Please wait...",
  },
  EDITS_ARE_FREE: {
    image: DataCorrectPlaceholder,
    type: 'success',
    title: 'Endorsement cost calculated...',
    subtitle: "Great news! These edits are free. ",
  },
};

export const STEPPER_DATA: Record<IBulkOperation, string[]> = {
  ADD: [
    'Upload Addition Data',
    'Review Uploaded Data',
    'Submit Addition Request',
  ],
  DELETE: [
    'Upload Deletion Data',
    'Review Uploaded Data',
    'Submit Deletion Request',
  ],
  EDIT: ['Select Members for Edit', 'View Edit Endorsement Cost'],
};

export const VIDEO_URLS: Record<IBulkOperation, string> = {
  ADD: 'https://www.youtube.com/embed/jCn9A60pABo?si=sUNtWqljpGrq3DmI',
  DELETE: 'https://www.youtube.com/embed/Rjugsk0duTY?si=yl60th3dH1D0-qJw',
  EDIT: '',
};

export const MEMBER_EDIT_REVIEW_ICONS: Record<IEditableFields, string> = {
  name: ProfileIcon,
  gender: ManIcon,
  dob: CalenderRoundedIcon,
  relationship: ProfileTwoUserIcon,
  mobile: PhoneIcon,
  email: SmsIcon,
};

export const EDIT_FIELD_NAME_MAP: Record<IEditableFields, string> = {
  name: 'Name',
  gender: 'Gender',
  dob: 'Date of Birth',
  relationship: 'Relationship',
  mobile: 'Mobile Number',
  email: 'Email Id',
};

export const GENDER_DROPDOWN_ITEMS = [
  {
    name: 'Male',
    value: 'male',
  },
  {
    name: 'Female',
    value: 'female',
  },
];

export const RELATIONSHIP_DROPDOWN_ITEMS = [
  {
    name: 'Spouse',
    value: 'spouse',
  },
  {
    name: 'Child',
    value: 'child',
  },
  {
    name: 'Parent',
    value: 'parent',
  },
  {
    name: 'Parent-in-law',
    value: 'parent-in-law',
  },
];

export const SUBMIT_SCREEN_COST_REFUND: Record<
  IBulkOperation,
  Record<string, string>
> = {
  ADD: {
    costText: 'Cost',
    operationText: 'Added',
    submittedText: 'Adding'
  },
  EDIT: {
    costText: 'Cost',
    operationText: 'Edited',
    submittedText: 'Editing'
  },
  DELETE: {
    costText: 'Refund',
    operationText: 'Deleted',
    submittedText: 'Deleting'
  },
};
