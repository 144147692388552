import {
  AssignmentDarkIcon,
  AssignmentLightIcon,
  CardDarkIcon,
  CardLightIcon,
  CardtickDarkIcon,
  CardtickLightIcon,
  CheckmarkCircleDarkIcon,
  CheckmarkCircleLightIcon,
  CrossOutlineIcon,
  FlagLightIcon,
  MailDarkIcon,
  MailLightIcon,
  SearchDarkIcon,
  SearchLightIcon,
} from '../../../assets/images';
import { IEndoStatusMessage } from './types';

export const ENDO_STATUS_MESSAGES: IEndoStatusMessage = {
  DRAFT: {
    statusText: 'Data submitted',
    type: 'warning',
    message: 'Accepting changes in Data',
    progressMessage: 'Data Received',
    progressIcon: {
      DONE: FlagLightIcon,
      PENDING: FlagLightIcon,
    },
  },
  IN_PROGRESS: {
    statusText: 'Review by Loop',
    type: 'warning',
    message: 'Your data is being reviewed by our team',
    progressMessage: 'Review by Loop',
    progressIcon: {
      DONE: SearchLightIcon,
      PENDING: SearchDarkIcon,
    },
  },
  SENT_TO_INSURER: {
    statusText: 'Data sent to insurer',
    type: 'warning',
    message: 'Data sent to insurer for endorsement',
    progressMessage: 'Sent to Insurer',
    progressIcon: {
      DONE: MailLightIcon,
      PENDING: MailDarkIcon,
    },
  },
  ENDO_DECLINED: {
    statusText: 'Rejected by Insurer',
    type: 'error',
    message:
      'Endorsement has been rejected by insurer. Reason to be provided by CSM',
    progressMessage: 'Endorsement rejected',
    progressIcon: {
      ERROR: CrossOutlineIcon,
    },
  },
  ENDO_COMPLETE: {
    statusText: 'Endorsement complete',
    type: 'success2',
    message: 'Endorsement has been completed',
    progressMessage: 'Endorsement Completed',
    progressIcon: {
      DONE: CheckmarkCircleLightIcon,
      PENDING: CheckmarkCircleDarkIcon,
    },
  },
  READY_FOR_TPA: {
    statusText: 'UHID under process',
    type: 'warning',
    message: 'Unique Health ID for e-cards being generated by TPA',
    progressMessage: 'UHID generated by TPA',
    progressIcon: {
      DONE: CardLightIcon,
      PENDING: CardDarkIcon,
    },
  },
  RECEIVED_UHIDS: {
    statusText: 'E-cards received',
    type: 'warning',
    message: 'E-cards are being updated with UHID',
    progressMessage: 'E-cards generated',
    progressIcon: {
      DONE: CardtickLightIcon,
      PENDING: CardtickDarkIcon,
    },
  },
  ENDO_RECEIVED: {
    statusText: 'Approved by Insurer',
    type: 'warning',
    message: 'Endorsement has been accepted by insurer. Lives are insured',
    progressMessage: 'Review by Insurer',
    progressIcon: {
      DONE: AssignmentLightIcon,
      PENDING: AssignmentDarkIcon,
    },
  },
};
