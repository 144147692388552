import styled from 'styled-components';
export const StyledWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledIcon = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  gap: 4px;
`;
