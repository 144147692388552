import styled from 'styled-components';
import theme from '../../../theme';

export const StyledZeroStateCard = styled.div`
  gap: 12px;
  height: 100%;
  display: flex;
  padding: 16px;
  align-items: center;
  text-transform: none;
`;

export const StyledZeroStateCardContent = styled.div`
  flex: 2;
  gap: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const StyledZeroCardSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const StyledZeroStateCardImageContainer = styled.div`
  flex: 3;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  min-width: 225px;
  height: 100%;
  min-height: 232px;
  background-color: ${theme.colors.mintFrost};
`;

export const StyledZeroStateCardImage = styled.img`
  object-fit: contain;
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 95%;
  height: 95%;
  transform: translateX(-50%);
`;
