import React, { useEffect } from 'react';
import { StyledHeaderContent, StyledIconDescription } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import CDAccountCard from '../../containers/CDAccountCard';
import { WalletDarkIcon } from '../../../assets/images';
import useFetchCDAccounts from './hooks/useFetchCDAccounts';
import Loader from '../../atoms/Spinner';
import { useLocation } from 'react-router-dom';
import useTrackPage from '../../../utils/segment/hooks/useTrackPage';
import { useSelector } from 'react-redux';
import { selectEndorsementList } from '../../../redux/slices/hrdRevampRedux';
import { getFirstOngoingEndoCDAccount } from './utils';

const CDAccounts = () => {
  const { cdAccounts, isLoading } = useFetchCDAccounts();
  const endorsements = useSelector(selectEndorsementList).data;

  const firstOngoingEndoAccountId = getFirstOngoingEndoCDAccount(
    cdAccounts,
    endorsements,
  );

  const selectedCdAccount = useLocation().hash;
  useEffect(() => {
    if (selectedCdAccount && cdAccounts) {
      const selectedId = document.getElementById(
        selectedCdAccount.substring(1),
      );
      selectedId?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [selectedCdAccount, cdAccounts]);

  useTrackPage({
    selected_cd_account_id: selectedCdAccount,
  });

  return (
    <>
      <StyledHeaderContent>
        <Typography variant="title3" weight="medium">
          CD Accounts
        </Typography>
        <StyledIconDescription>
          <img src={WalletDarkIcon} alt={'cd-balance'} />
          <Typography variant="small" weight="regular" color="secondary">
            Balance has been calculated by Loop based on the rater provided by
            insurer. Actual amounts may vary slightly!
          </Typography>
        </StyledIconDescription>
      </StyledHeaderContent>
      {isLoading && !cdAccounts.length ? (
        <Loader />
      ) : (
        cdAccounts.map((row, key) => (
          <CDAccountCard
            key={key}
            data={row}
            isFirstOngoing={firstOngoingEndoAccountId === row.id}
          />
        ))
      )}
    </>
  );
};

export default CDAccounts;
