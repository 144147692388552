import { Card, Typography } from '@loophealth/loop-ui-web-library';
import React, { FC } from 'react';
import {
  StyledZeroCardSubtitle,
  StyledZeroStateCard,
  StyledZeroStateCardContent,
  StyledZeroStateCardImage,
  StyledZeroStateCardImageContainer,
} from './styles';
import { IZeroStateCardProps } from './types';

const ZeroStateCard: FC<IZeroStateCardProps> = ({
  title,
  subtitle,
  image,
  note,
}) => {
  return (
    <Card>
      <StyledZeroStateCard>
        <StyledZeroStateCardContent>
          <Typography variant="medium" weight="semiBold" color="emerald">
            {title}
          </Typography>
          <StyledZeroCardSubtitle>
            <Typography variant="small">{subtitle}</Typography>
            <Typography variant="extraSmall" color='secondary'>{note}</Typography>
          </StyledZeroCardSubtitle>
        </StyledZeroStateCardContent>

        <StyledZeroStateCardImageContainer data-testid="graphic">
          <StyledZeroStateCardImage src={image} />
        </StyledZeroStateCardImageContainer>
      </StyledZeroStateCard>
    </Card>
  );
};

export default ZeroStateCard;
