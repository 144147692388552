import React, { FC, useState } from 'react';
import { IPolicyListItemProps } from './types';
import {
  HorizontalSeparator,
  StyledBullet,
  StyledHeaderButtonContainer,
  StyledPolicyItemAlertsContainer,
  StyledPolicyListItemContainer,
  StyledPolicyListItemFooter,
  StyledPolicyListItemHeader,
  StyledPolicyListItemHeaderInsurerDetails,
  StyledPolicyListItemHeaderInsurerInfo,
  StyledPolicyListItemHeaderMonetaryInfo,
} from './styles';
import {
  Alerts,
  CardIcon,
  LoopButton,
  Shadow,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ArrowRightIcon,
  CDBalIcon,
  CalendarColoredIcon,
  LifeCountIcon,
  PersonIcon,
} from '../../../assets/images/';
import {
  convertDateToFirebaseTimestamp,
  convertToDate,
  formatCurrencyAmount,
} from '../../../utils/common/Utilities';
import { POLICY_LIST_ITEM_TITLE } from './constants';
import PolicyItem from './PolicyItem';
import { PolicyTypeConstants } from '../../../utils/constants';
import AlertSidebar from '../AlertSidebar';
import { getAlertText } from './utils';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import useSegment from '../../../utils/segment/hooks/useSegment';

const PolicyListItem: FC<IPolicyListItemProps> = ({ policy, alertData }) => {
  const history = useHistory();
  const location = useLocation();
  const trackView = useSegment('page');

  const handleDetailsButtonClick = () => {
    const policyProperties: Record<string, unknown> = {
      policy_id: policy.id ?? '',
      policy_type: policy.policyType ?? '',
      policy_number: policy.policyNumber,
      employee_count: policy.employeeCount,
    };
    trackView({
      name: 'POLICY_DETAILS_' + policy.policyType,
      properties: {
        pathname: location.pathname,
        policyProperties,
      },
    });
    history.push('/policy-details?policyId=' + policy.id);
  };

  const [isAlertsSidebarVisible, setIsAlertsSidebarVisible] = useState(false);
  const handleViewAlert = () => setIsAlertsSidebarVisible(true);

  const alertText = alertData && getAlertText(alertData);
  const validTillDate =
    convertToDate(
      convertDateToFirebaseTimestamp(policy.policyEndDate),
      'DD/MMM/YYYY',
    ) ?? '--';

  const handleStopPropogation: React.MouseEventHandler<HTMLDivElement> = (e) =>
    e.stopPropagation();

  return (
    <Shadow variant="smooth">
      <WithThemeProvider>
        <StyledPolicyListItemContainer
          data-testid="policy-list-item-container"
          onClick={handleDetailsButtonClick}
        >
          <StyledPolicyListItemHeader data-testid="policy-list-item-header">
            <CardIcon imageUrl={policy.insurerLogo} />

            <StyledPolicyListItemHeaderInsurerInfo data-testid="policy-list-item-insurer-info">
              <Typography variant="medium" weight="semiBold">
                {POLICY_LIST_ITEM_TITLE[policy.policyType]}
                {(policy.isParentalPolicy && ' (Parental)') ||
                  (policy.isTopUpPolicy && ' (Top Up)')}
              </Typography>

              <StyledPolicyListItemHeaderInsurerDetails>
                <Typography variant="small" color="secondary">
                  {policy.insurerName}
                </Typography>

                {policy.nickName && (
                  <>
                    <StyledBullet />
                    <Typography variant="small" color="secondary">
                      {policy.nickName}
                    </Typography>
                  </>
                )}

                {policy.policyNumber && (
                  <>
                    <StyledBullet />
                    <Typography variant="small" color="secondary">
                      Policy No: {policy.policyNumber}
                    </Typography>
                  </>
                )}
              </StyledPolicyListItemHeaderInsurerDetails>
            </StyledPolicyListItemHeaderInsurerInfo>

            <StyledPolicyListItemHeaderMonetaryInfo data-testid="policy-list-item-monetary-info">
              <StyledHeaderButtonContainer
                data-testid="policy-list-item-details-button"
                onClick={handleStopPropogation}
              >
                <LoopButton
                  variant="secondary"
                  size="medium"
                  onClick={handleDetailsButtonClick}
                  iconSrc={ArrowRightIcon}
                  iconOrder="right"
                >
                  Policy Details
                </LoopButton>
              </StyledHeaderButtonContainer>
            </StyledPolicyListItemHeaderMonetaryInfo>
          </StyledPolicyListItemHeader>
          <HorizontalSeparator />

          <StyledPolicyListItemFooter data-testid="policy-list-item-footer">
            {policy.policyType === PolicyTypeConstants.GMC ? (
              <PolicyItem
                icon={LifeCountIcon}
                label="Total Life Count"
                value={policy.livesCount}
              />
            ) : null}
            <PolicyItem
              icon={PersonIcon}
              label="Total Employee Count"
              value={policy.employeeCount}
            />
            {policy.isCdAccountPresent && (
              <PolicyItem
                icon={CDBalIcon}
                label="Available CD Balance"
                value={formatCurrencyAmount(policy.cdBalance)}
              />
            )}
            <PolicyItem
              icon={CalendarColoredIcon}
              label="Valid Till"
              value={validTillDate}
            />
          </StyledPolicyListItemFooter>
          {alertText && (
            <StyledPolicyItemAlertsContainer onClick={handleStopPropogation}>
              <AlertSidebar
                setIsAlertsOpen={setIsAlertsSidebarVisible}
                isAlertsOpen={isAlertsSidebarVisible}
                alerts={[alertData]}
              />
              <Alerts.StatusAlert
                text={
                  <Typography variant="small" weight="medium" color="error">
                    {alertText}
                  </Typography>
                }
                variant="over"
                onButtonClick={handleViewAlert}
                buttonText="View Issues"
              />
            </StyledPolicyItemAlertsContainer>
          )}
        </StyledPolicyListItemContainer>
      </WithThemeProvider>
    </Shadow>
  );
};

export default PolicyListItem;
